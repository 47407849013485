<template>
  <div>
    <vca-column class="event-card" :class="'event-' + event.type_of_event">
      <h2>{{ $t("events.informations.header") }}</h2>
      <EventApplicationDetails :event="expandedEvent" />
      <div v-if="showApplication">
        <br />
        <CrewEventApplicationParticipation
          v-if="event.type_of_event == 'crew_meeting'"
          @close="close()"
          :event="event"
        />
        <EventApplicationParticipation v-else @close="close()" :event="event" />
      </div>
    </vca-column>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import EventApplicationDetails from "@/components/events/application/EventApplicationDetails";
import EventApplicationParticipation from "@/components/events/application/EventApplicationParticipation";
import CrewEventApplicationParticipation from "@/components/events/application/CrewEventApplicationParticipation";
export default {
  name: "EventApplication",
  components: {
    EventApplicationDetails,
    EventApplicationParticipation,
    CrewEventApplicationParticipation,
  },
  props: {
    event: {
      type: Object,
      default: null,
    },
    showApplication: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },
  },
  created() {
    this.create.event_id = this.event.id;
    this.create.user_id = this.user.id;
  },
  computed: {
    expandedEvent() {
      let row = {};
      row["shareURL"] = "/events/" + this.event.id;
      row["eventIcon"] = require("@/assets/icons/icon-" +
        this.event.type_of_event +
        ".png");
      row["eventArtists"] =
        this.event.artists && this.event.artists.length > 0
          ? this.event.artists.map((el) => el.name).join(", ")
          : " - ";
      row["eventLocation"] = this.event.location.name
        ? this.event.location.name + ", " + this.event.location.city
        : " - ";
      row["eventWebsite"] = this.event.website ? this.event.website : " - ";

      row["eventCrew"] =
        this.event.crew.name != ""
          ? this.event.crew.name
          : this.$t("events.list.crew.office_hh");

      return {
        ...this.event,
        ...row,
      };
    },
    ...mapGetters({
      user: "user/current",
      poolEventPermissions: "user/roles/poolEventPermissions",
      hasPoolPermission: "user/roles/hasPoolPermission",
      hasSystemPermission: "user/roles/hasSystemPermission",
    }),
    current: {
      get() {
        return this.$store.state.user.participations.current;
      },
      set(value) {
        this.$store.commit("user/participations/current", value);
      },
    },
    canEdit() {
      return (
        this.hasSystemPermission() ||
        (this.hasPoolPermission(this.poolEventPermissions) &&
          this.user.crew &&
          this.event.crew &&
          this.user.crew.crew_id == this.event.crew.id)
      );
    },
    create: {
      set(value) {
        this.$store.commit("events/participations/create", value);
      },
      get() {
        return this.$store.state.events.participations.create;
      },
    },
  },
};
</script>
<style lang="scss" scoped>
.event-card {
  h2 {
    font-size: 1.5em;
    margin-top: 10px;
  }

  padding: 10px;
  border-radius: 5px;
}
</style>
