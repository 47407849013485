<template>
  <div class="table-container">
    <VcaTable
      store="user/participations"
      :list="getList()"
      :colspan="6"
      :searchLabel="$t('profile.events.search')"
    >
      <template v-slot:colspan>
        <col width="30%" />
        <col width="10%" />
        <col width="30%" />
        <col width="30%" />
        <col width="10%" />
        <col width="1%" />
      </template>

      <template v-slot:desktop-filter>
        <vca-accordion :header="$t('profile.events.filter')">
          <div slot="body">
            <EventFilter v-model="filter" :participations="list" />
          </div>
        </vca-accordion>
      </template>

      <template v-slot:desktop-header>
        <th class="vca-table-cell sortable" @click="sort('event.name')">
          <label> {{ $t("events.label") }} &varr;</label>
        </th>
        <th class="vca-table-cell sortable" @click="sort('event.start_at')">
          <label> {{ $t("events.dates.label") }} &varr;</label>
        </th>
        <th
          class="vca-table-cell sortable"
          @click="sort('event.location.name')"
        >
          <label> {{ $t("events.location.label") }} &varr;</label>
        </th>
        <th class="vca-table-cell sortable" @click="sort('event.crew.name')">
          <label> {{ $t("events.crew.label") }} &varr;</label>
        </th>
        <th class="vca-table-cell sortable" @click="sort('status_translation')">
          <label>
            {{ $t("events.participations.status.header") }}
            &varr;</label
          >
        </th>
        <th class="vca-table-cell">
          <label>{{ $t("table.options") }}</label>
        </th>
      </template>

      <template v-slot:desktop-content>
        <tr
          class="vca-table-row"
          :class="{ last: index + 1 == pg.pageSize }"
          v-for="(res, index) in getList()"
          :key="index"
        >
          <td class="vca-table-cell">
            <label>
              {{ res.event.name }} ({{
                $t("events.eventtypes." + res.event.type_of_event + ".title")
              }})</label
            >
          </td>
          <td class="vca-table-cell">
            <label> {{ eventPeriod(res.event) }}</label>
          </td>
          <td class="vca-table-cell">
            <label> {{ res.event.location.name }}</label>
          </td>
          <td class="vca-table-cell">
            <label v-if="res.event.crew.name != ''">
              {{ res.event.crew.name }}</label
            >
            <label v-else> {{ $t("events.list.crew.office_hh") }}</label>
          </td>
          <td class="vca-table-cell">
            <label> {{ res.status_translation }}</label>
          </td>
          <td class="vca-table-cell">
            <label class="table-options">
              <img
                class="editable"
                @click="setCurrent(res)"
                src="@/assets/icons/edit.png"
                :title="$t('button.edit')"
                :alt="$t('button.edit')"
              />
              <img
                class="editable"
                v-if="res.event.location.name"
                @click="setMap(res)"
                src="@/assets/icons/map.png"
                :title="$t('button.map')"
                :alt="$t('button.map')"
              />
            </label>
          </td>
        </tr>
      </template>

      <template v-slot:mobile-filter>
        <button class="vca-button" @click="do_filter = true">
          {{ $t("profile.events.filter") }}
        </button>
        <vca-popup
          v-if="do_filter"
          :show="do_filter"
          :title="$t('profile.events.filter')"
          @close="do_filter = false"
        >
          <EventFilter v-model="filter" :participations="list" />
        </vca-popup>
      </template>

      <template v-slot:mobile-header>
        <div><img width="20px" src="~@/assets/icons/sort.png" /></div>
        <div class="sortable" @click="sort('event_id')">
          <label>{{ $t("events.label") }} &varr;</label>
        </div>
        <div class="sortable" @click="sort('status_translation')">
          <label>{{ $t("events.participations.status.header") }} &varr;</label>
        </div>
      </template>

      <template v-slot:mobile-content>
        <tr
          class="vca-table-row"
          @click="setCurrent(res)"
          :class="{ last: index + 1 == pg.pageSize }"
          v-for="(res, index) in getList()"
          :key="index"
        >
          <td class="vca-table-cell">
            <vca-column>
              <vca-row>
                <div class="vca-left vca-table-index">
                  <span class="bold">{{ res.event.name }}</span>
                </div>
                <div class="vca-right vca-table-index">
                  {{ res.status_translation }}
                </div>
              </vca-row>
              <vca-row>
                <div class="vca-left vca-table-index">
                  {{ $t("events.crew.label") }}:&nbsp;
                  <span v-if="res.event.crew.name != ''">
                    {{ res.event.crew.name }}</span
                  >
                  <span v-else> {{ $t("events.list.crew.office_hh") }}</span>
                </div>
              </vca-row>
            </vca-column>
          </td>
        </tr>
      </template>
    </VcaTable>
    <vca-popup
      :show="showMap && map.location && map.location.place_id != ''"
      :title="$t('events.map')"
      @close="showMap = false"
    >
      <EventMap v-model="map" />
    </vca-popup>
  </div>
</template>
<script>
import { mapGetters, mapState } from "vuex";
import VcaTable from "@/components/utils/VcaTable";
import EventMap from "@/components/events/EventMap";
import EventFilter from "@/components/account/events/AccountEventFilter";
export default {
  name: "AccountEventsTable",
  components: { VcaTable, EventFilter, EventMap },
  data() {
    return {
      showMap: false,
      map: {},
      store: "user/participations/pg",
      do_filter: false,
      currentFilter: {},
      currentTextFilter: "",
    };
  },
  watch: {
    pg: {
      handler(val) {
        this.$store.commit(this.store + "/pagination", val);
      },
      deep: true,
    },
  },
  created() {
    if (Object.keys(this.filter).length == 0) {
      this.filter = { crews: [], type: [], state: [] };
    }
  },
  computed: {
    filter: {
      set(value) {
        this.pg.filterObject = value;
      },
      get() {
        return this.pg.filterObject;
      },
    },
    ...mapState({
      pg(state, getters) {
        if (this.store) {
          return getters[`${this.store}/pagination`];
        }
      },
    }),
    ...mapGetters({
      list: "events/participations/list",
    }),
    emptyFilter() {
      return (
        (!this.pg.filter || this.pg.filter == "") &&
        this.filter.crews.length == 0 &&
        this.filter.type.length == 0 &&
        this.filter.state.length == 0
      );
    },
    sameFilter() {
      return (
        Object.entries(this.currentFilter).toString() ===
          Object.entries(this.filter).toString() &&
        this.currentTextFilter == this.pg.filter
      );
    },
  },
  methods: {
    eventPeriod(event) {
      return (
        this.datetime(event.start_at) + " - " + this.datetime(event.end_at)
      );
    },
    setMap(value) {
      this.showMap = true;
      this.map = value.event;
    },
    setCurrent(value) {
      if (this.current && this.current.id == value.id) {
        this.$store.commit("user/participations/current", null);
      } else {
        this.$store.commit("user/participations/current", value);
      }
    },
    getCityList(value) {
      return value.map((el) => el.name + " (" + el.country + ")").join(", ");
    },
    getList() {
      // If the list is empty, we have an empty array
      if (!this.list) {
        return [];
      }

      // If the current page is out of scope, set it to last page
      if (this.pg.currentPage > this.pg.pageCount) {
        this.$store.commit(this.store + "/prevPage");
      }

      // Create a list for filtering and start filtering
      var filteredList = this.list;

      filteredList = filteredList.filter((row, index) => {
        // Add a rank to the current entry
        row.rank = index + 1;
        row.status_translation = this.$t(
          "events.participations.status." + row.status
        );
        if (row.event.type_of_event == "crew_meeting") {
          row.status_translation = this.$t(
            "events.participations.status." +
              row.event.type_of_event +
              "." +
              row.status
          );
        }

        // If the filter is empty, everything is fine
        if (this.emptyFilter) {
          return true;
        }

        if (
          this.filter.crews.length > 0 &&
          !this.filter.crews.find((el) => row.event.crew.id == el.value)
        ) {
          return false;
        }

        if (
          this.filter.state.length > 0 &&
          !this.filter.state.find((el) => row.status == el)
        ) {
          return false;
        }

        if (
          this.filter.type.length > 0 &&
          !this.filter.type.find((el) => row.event.type_of_event == el)
        ) {
          return false;
        }

        // Filter for the current value of row.team_name
        return (
          row.crew.name.toLowerCase().indexOf(this.pg.filter.toLowerCase()) > -1
        );
      });

      // Set the length of the list to the current filtered list
      this.pg.listLength = filteredList.length == 0 ? 1 : filteredList.length;
      if (
        this.pg.listLength < this.pg.pageSize ||
        this.pg.pageSize > filteredList.length
      ) {
        // If the length is smaller than the current page size, we adjust the pagesize to the length of the filtered list
        this.pg.pageSize = this.pg.listLength;
      } else if (!this.sameFilter || this.initial) {
        // If the filter has changed, we resize the list
        this.initial = false;
        this.pg.pageSize =
          this.pg.currentPageSize > this.pg.listLength
            ? this.pg.listLength
            : this.pg.currentPageSize;
      }
      // Set the new filters
      if (!this.sameFilter) {
        this.currentTextFilter = this.pg.filter;
        this.currentFilter = JSON.parse(JSON.stringify(this.filter));
      }

      // Now we filter the list to the current pageSize and paginate it
      return filteredList.filter((row, index) => {
        let start = (this.pg.currentPage - 1) * this.pg.pageSize;
        let end = this.pg.currentPage * this.pg.pageSize;
        if (index >= start && index < end) return true;
      });
    },
    sort(col) {
      // When sorting, we set the current page to 1
      this.$store.commit(this.store + "/firstPage");

      // If we have an empty list, return an empty array
      if (!this.list) {
        return [];
      }

      // Define the sorting direction, if current sort is the same as the column to sort, then switch direction
      if (this.pg.currentSort === col) {
        this.pg.currentSortDir =
          this.pg.currentSortDir === "asc" ? "desc" : "asc";
      } else {
        this.pg.currentSort = col;
      }

      // Sort the list depending on the column and the direction
      this.list.sort((a, b) => {
        var final_key = this.pg.currentSort;

        // Sort nested objects if sorting column contains a dot (.)
        if (col.indexOf(".") !== -1) {
          var column = col.split(".");
          var len = column.length;
          var i = 0;
          while (i < len - 1) {
            a = a[column[i]];
            b = b[column[i]];
            i++;
            final_key = column[i];
          }
        }

        // Set zero 0 at the end
        // if (parseInt(a[final_key]) == 0) return 1;
        // if (parseInt(b[final_key]) == 0) return -1;

        // Comapre integer values
        if (
          a[final_key] === parseInt(a[final_key], 10) ||
          a[final_key] === parseFloat(a[final_key], 10)
        ) {
          return this.pg.currentSortDir === "desc"
            ? a[final_key] - b[final_key]
            : b[final_key] - a[final_key];
        }

        // Compare string values
        let modifier = 1;
        if (this.pg.currentSortDir === "desc") modifier = -1;
        if (a[final_key].toLowerCase() < b[final_key].toLowerCase())
          return -1 * modifier;
        if (a[final_key].toLowerCase() > b[final_key].toLowerCase())
          return 1 * modifier;
        return 0;
      });
    },
  },
};
</script>
